import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import CourseCompleted1 from '../../assets/trellis/achievements/BlueFlax.gif';
import Bootcamp1 from '../../assets/trellis/achievements/Bootcamp1.gif';
import Bootcamp2 from '../../assets/trellis/achievements/Bootcamp2.gif';
import Bootcamp3 from '../../assets/trellis/achievements/Bootcamp3.gif';
import Bootcamp4 from '../../assets/trellis/achievements/Bootcamp4.gif';
import Bootcamp5 from '../../assets/trellis/achievements/Bootcamp5.gif';
import RedLeaf from '../../assets/trellis/achievements/MagentaLeaf.gif';
import CourseCompleted3 from '../../assets/trellis/achievements/OrangeDahlia.gif';
import WWHappinessScoreImproved from '../../assets/trellis/achievements/PottedBlue2.gif';
import WWFourConsecutiveCompletions from '../../assets/trellis/achievements/PottedBlueFlower.gif';
import WWOverallImprovement from '../../assets/trellis/achievements/PottedLavender.gif';
import WWHappinessLadderImproved from '../../assets/trellis/achievements/PottedMagentaAndMarigold.gif';
import CommonLessonCompleted from '../../assets/trellis/achievements/PottedOrange.gif';
import PottedOrange1 from '../../assets/trellis/achievements/PottedOrange_1.gif';
import PottedOrange2 from '../../assets/trellis/achievements/PottedOrange_2.gif';
import PottedOrange3 from '../../assets/trellis/achievements/PottedOrange_3.gif';
import LoggedAfterPause from '../../assets/trellis/achievements/PottedPinkSpray.gif';
import LoggedInFourWeeks from '../../assets/trellis/achievements/PottedPurple.gif';
import LoggedFiveInTwoWeeks from '../../assets/trellis/achievements/PottedRed.gif';
import JumpStartLessonCompleted from '../../assets/trellis/achievements/PottedRedAndYellow.gif';
import JumpStartLessonCompleted1 from '../../assets/trellis/achievements/PottedRedAndYellow_1.gif';
import JumpStartLessonCompleted2 from '../../assets/trellis/achievements/PottedRedAndYellow_2.gif';
import JumpStartLessonCompleted3 from '../../assets/trellis/achievements/PottedRedAndYellow_3.gif';
import JumpStartLessonCompleted4 from '../../assets/trellis/achievements/PottedRedAndYellow_4.gif';
import CourseCompleted2 from '../../assets/trellis/achievements/RedDaisy.gif';
import CategoryCompleted from '../../assets/trellis/achievements/Sunflower.gif';
import YellowLeaf from '../../assets/trellis/achievements/YellowLeaf.gif';
import {useInjection} from '../../ioc';
import type {IGoalService} from '../../task/service';
import {GOAL_SERVICE} from '../../task/service';
import {ETrellisPopUpType} from '../../trellis-visualization/model';
import {Button} from '../button';
import {Modal} from '../modal';
import type {IPopUpMetaData} from './interface/pop-up-modal.interface';
import styles from './pop-up-modal.module.scss';

interface IPopUpAchievementProps {
  streaksCount: number;
  type: ETrellisPopUpType;
  title: string;
  show: boolean;
  meta?: IPopUpMetaData;

  onClose: () => void;
}

interface IServiceProps {
  goalService: IGoalService;
}

export const achievementGIFs: {[key: number]: string | string[]} = {
  [ETrellisPopUpType.SkillStreak]: [YellowLeaf, RedLeaf],
  [ETrellisPopUpType.CourseStreak]: [CourseCompleted1, CourseCompleted2, CourseCompleted3],
  [ETrellisPopUpType.CategoryStreak]: CategoryCompleted,
  [ETrellisPopUpType.LoggedFiveInTwoWeeks]: LoggedFiveInTwoWeeks, // Potted Red
  [ETrellisPopUpType.LoggedInFourWeeks]: LoggedInFourWeeks, // PottedPurple
  [ETrellisPopUpType.LoggedAfterPause]: LoggedAfterPause, // PottedPinkSpray
  [ETrellisPopUpType.CommonLessonCompleted]: [PottedOrange1, PottedOrange2, PottedOrange3, CommonLessonCompleted], // PottedOrange
  [ETrellisPopUpType.JumpStartLessonCompleted]: [
    JumpStartLessonCompleted1,
    JumpStartLessonCompleted2,
    JumpStartLessonCompleted3,
    JumpStartLessonCompleted4,
    JumpStartLessonCompleted,
  ], // PottedRedAndYellow
  [ETrellisPopUpType.BootcampLessonCompleted]: [Bootcamp1, Bootcamp2, Bootcamp3, Bootcamp4, Bootcamp5], // Bootcamp
  [ETrellisPopUpType.WWFourConsecutiveCompletions]: WWFourConsecutiveCompletions, // PottedBlue
  [ETrellisPopUpType.WWOverallImprovement]: WWOverallImprovement, // PottedLavender
  [ETrellisPopUpType.WWHappinessScoreImproved]: WWHappinessScoreImproved, // PottedBlue2
  [ETrellisPopUpType.WWHappinessLadderImproved]: WWHappinessLadderImproved, // PottedMarigold
};

const PopUpAchievementModal: FC<IPopUpAchievementProps & IServiceProps> = ({
  streaksCount,
  show,
  type,
  title,
  meta,
  goalService,
  onClose,
}) => {
  const [gifURL, setGifURL] = useState<string>();

  useEffect(() => {
    if (type === ETrellisPopUpType.SkillStreak) {
      const randomIndex = Math.floor(Math.random() * achievementGIFs[type].length);
      setGifURL(achievementGIFs[type][randomIndex]);
    } else if (type === ETrellisPopUpType.CourseStreak) {
      const randomIndex = Math.floor(Math.random() * achievementGIFs[type].length);
      setGifURL(achievementGIFs[type][randomIndex]);
    } else if (type === ETrellisPopUpType.BootcampLessonCompleted) {
      setGifURL(achievementGIFs[type][streaksCount - 1 || 0]);
    } else if (type === ETrellisPopUpType.JumpStartLessonCompleted) {
      const index = streaksCount < 5 ? streaksCount - 1 : achievementGIFs[type].length - 1;
      setGifURL(achievementGIFs[type][index]);
    } else if (type === ETrellisPopUpType.CommonLessonCompleted) {
      const streaksIndex: {[key: number]: number} = {
        5: 0,
        10: 1,
        20: 2,
      };

      const index = [5, 10, 20].includes(streaksCount) ? streaksIndex[streaksCount] : achievementGIFs[type].length - 1;
      setGifURL(achievementGIFs[type][index]);
    } else {
      setGifURL(achievementGIFs[type] as string);
    }
  }, [type, streaksCount]);

  const handleClose = () => {
    onClose();

    if (type === ETrellisPopUpType.CourseStreak && meta?.goalWorksheetId && meta?.courseId && !meta?.isArchived) {
      // Setting reviewWorkflow will trigger modal window
      goalService.reviewWorkflow = {courseId: meta.courseId, worksheetId: meta.goalWorksheetId, title: meta.goal || ''};
    }
  };

  return (
    <>
      {show && (
        <Modal zIndex={1001}>
          <div className={styles.root}>
            <p className={styles.title}>{title}</p>
            <p className={styles.message}>
              You can view it on{' '}
              <Link to="/home/my-roadmap" className={styles.link}>
                My Journey
              </Link>
            </p>
            <img src={gifURL} alt="Achievement" height={200} />
            <Button testid="button" className={styles.button} onClick={handleClose}>
              OK
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

const PopUpAchievementModalObserver = observer(PopUpAchievementModal);
const PopUpAchievementModalInjected: FC<IPopUpAchievementProps> = (props) => (
  <PopUpAchievementModalObserver {...props} goalService={useInjection(GOAL_SERVICE)} />
);

export {PopUpAchievementModalInjected as PopUpAchievementModal, PopUpAchievementModalObserver};
