import type {ShepherdOptionsWithType} from 'react-shepherd';

const tours: Record<string, ShepherdOptionsWithType[]> = {
  '/home/my-dashboard': [
    // step 0 hello/welcome
    {
      id: 'intro',
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: 'Exit Tour',
          type: 'cancel',
        },
        {
          classes: 'shepherd-button-primary',
          text: 'Next',
          type: 'next',
        },
      ],
      scrollTo: false,
      cancelIcon: {
        enabled: false,
      },
      title: 'Welcome to <span style="color:#aa1059; font-weight:bold;">&nbsp;Trellus Elevate!</span>',
      text: "Let's take a quick tour.",
    },
    // step 1 "top-nav-panel_profileAvatar"
    {
      id: 'profile',
      attachTo: {element: '#profile-avatar', on: 'bottom'},
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'Previous',
          type: 'back',
        },
        {
          classes: 'shepherd-button-primary',
          text: 'Next',
          type: 'next',
        },
      ],
      highlightClass: 'border',
      title: '<span style="color:#aa1059; font-weight:bold;">1/5 Your Profile Menu</span>',
      text: ['Access your Settings, Provider, and healthcare info.'],
    },
    // step 2 "top-nav-panel_notificationLink"
    {
      id: 'notification',
      attachTo: {element: '#notifications', on: 'bottom'},
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'Previous',
          type: 'back',
        },
        {
          classes: 'shepherd-button-primary',
          text: 'Next',
          type: 'next',
        },
      ],
      title: '<span style="color:#aa1059; font-weight:bold;">2/5 Notifications</span>',
      text: ['See your notifications here.'],
    },
    // step 3 "private-layout_leftSidebar"
    {
      id: 'left-sidebar',
      attachTo: {element: '#leftnav', on: 'right'},
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'Previous',
          type: 'back',
        },
        {
          classes: 'shepherd-button-primary',
          text: 'Next',
          type: 'next',
        },
      ],
      scrollTo: {behavior: 'smooth', block: 'nearest'},
      title: '<span style="color:#aa1059; font-weight:bold;">3/5 Navigation Menu</span>',
      text: ['Access your Dashboard, Reports, and more.'],
      beforeShowPromise: function () {
        return new Promise((resolve) => {
          // if the screen is small, the menu is hidden so we click the menu toggle button to open it
          const menuToggle: HTMLButtonElement | null = document.querySelector('#leftmenu-button');
          if (window.innerWidth < 1300 && menuToggle instanceof HTMLButtonElement) {
            menuToggle.click();
          }
          resolve(true);
        });
      },
      when: {
        show: () => {},
        hide: () => {
          // after this step we close the menu so it does not cover the next step
          const menuToggle: HTMLButtonElement | null = document.querySelector('#leftmenu-button');
          const leftBar: HTMLElement | null | undefined = document.querySelector('#leftnav')?.parentElement;
          const leftBarWidthCheck =
            leftBar?.clientWidth !== null && leftBar?.clientWidth !== undefined ? leftBar?.clientWidth > 0 : true;
          if (window.innerWidth < 1300 && menuToggle instanceof HTMLButtonElement && leftBarWidthCheck) {
            menuToggle.click();
          }
        },
      },
    },
    // removed step 4 because the right sidebar is no longer used
    // step 5 My Journey dashboard section
    {
      id: 'roadmap',
      attachTo: {element: '#My_Journey', on: 'top'},
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'Previous',
          type: 'back',
        },
        {
          classes: 'shepherd-button-primary',
          text: 'Next',
          type: 'next',
        },
      ],
      title: '<span style="color:#aa1059; font-weight:bold;">4/5 My Journey</span>',
      text: ['Your personalized courses will be assigned here.'],
      scrollTo: {behavior: 'smooth', block: 'end'},
    },
    // step 6 My To-Do List dashbaord section
    {
      id: 'todo',
      attachTo: {element: '#My_To-Do_list', on: 'top'},
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'Previous',
          type: 'back',
        },
        {
          classes: 'shepherd-button-end',
          text: 'Done',
          type: 'next',
        },
      ],
      title: '<span style="color:#aa1059; font-weight:bold;">5/5 My To-Do List</span>',
      text: ['Begin every visit here! <br><br> Start now!'],
      scrollTo: {behavior: 'smooth', block: 'end'},
    },
  ],
  // '/home/profile': [],
  // TODO add more tours for more routes here
};

function getTour(route: string): ShepherdOptionsWithType[] {
  return tours[route];
}

export {getTour};
