import type {FC} from 'react';
import React, {useCallback} from 'react';
import styles from './left-nav-panel.module.scss';
import type {INavItem, INavLink} from './nav-item';
import {NavItem, NavItemType} from './nav-item';
import {
  AddWeightIcon,
  AddWeightIconActive,
  AssessmentActiveIcon,
  AssessmentInactiveIcon,
  ChronicConditionChecklistActiveIcon,
  ChronicConditionChecklistIcon,
  DiseaseSeverityScreenerActiveIcon,
  DiseaseSeverityScreenerIcon,
  DocumentActiveIcon,
  DocumentIcon,
  EducationActiveIcon,
  EducationIcon,
  EmergencyCardActiveIcon,
  EmergencyCardIcon,
  EmotionalImpactScaleActiveIcon,
  EmotionalImpactScaleIcon,
  HomeActiveIcon,
  HomeIcon,
  LabResultActiveIcon,
  LabResultIcon,
  MedicationsActiveIcon,
  MedicationsIcon,
  MessagesActiveIcon,
  MessagesIcon,
  StrengthsAssessmentActiveIcon,
  StrengthsAssessmentIcon,
  SymptomTrackingActiveIcon,
  SymptomTrackingIcon,
  WeeklyWellnessReportActiveIcon,
  WeeklyWellnessReportIcon,
  VaccinationsIcon,
  VaccinationsActiveIcon,
  GlossaryOfMmMonitoring,
  GlossaryOfMmMonitoringActive,
  VaccinationsReference,
  VaccinationsReferenceActive,
  ReportsIcon,
  ReportsActiveIcon,
  GreyBellVibrateIcon,
  YellowBellVibrateIcon,
  GoalMenu,
  GoalMenuActive,
} from '../../../../assets/icons';
import {routes} from '../../../../routing';
import classNames from 'classnames';
import type {IAlertIndicatorService} from '../../../../alert-indicator';
import type {ILayoutsService} from '../../../service';
import {LAYOUTS_SERVICE, NavigationIds} from '../../../service';
import {useInjection} from '../../../../ioc';
import {observer} from 'mobx-react';

const HOME_ROUTE_VALUE = routes.home;

interface ILeftNavPanelProps {
  className?: string;
  onClose: () => void;
  open?: boolean;
  showAssessmentReportPageLink?: boolean;
  alertIndicator: IAlertIndicatorService;
  glossaryOfMedicationMonitoringLink: string;
  vaccinationReferenceGuideLink: string;
  onOpenEmergencyCardModal: () => void;
}

interface IService {
  layouts: ILayoutsService;
}

const LeftNavPanel: FC<ILeftNavPanelProps & IService> = ({
  className,
  onClose,
  open,
  showAssessmentReportPageLink,
  alertIndicator,
  layouts,
  glossaryOfMedicationMonitoringLink,
  vaccinationReferenceGuideLink,
  onOpenEmergencyCardModal,
}) => {
  const emergencyCardAction = useCallback(() => {
    onClose();
    onOpenEmergencyCardModal();
  }, [onClose, onOpenEmergencyCardModal]);

  const getNavItems: () => INavItem[] = () => {
    const resilienceReportLinks: INavLink[] = [
      {
        label: layouts.getNavigationLabel(NavigationIds.Resilience5Assessment) ?? 'Resilience-5 Report',
        route: `${HOME_ROUTE_VALUE}${routes.assessmentReport}`,
        icon: AssessmentInactiveIcon,
        activeIcon: AssessmentActiveIcon,
        isVisible: layouts.getNavigationVisibility(NavigationIds.Resilience5Assessment),
        notificationBubble: alertIndicator.hasAllowedResilienceAssessment,
      },
      {
        route: `${HOME_ROUTE_VALUE}${routes.resilienceAssessment}`,
        icon: AssessmentInactiveIcon,
        activeIcon: AssessmentActiveIcon,
        isVisible: false,
      },
    ];

    const assessmentsLinks: INavItem = {
      label: layouts.getNavigationLabel(NavigationIds.Assessments) ?? 'Assessments',
      notificationBubble: alertIndicator.hasEvenOneAllowedAssessment,
      subItems: [
        {
          label: layouts.getNavigationLabel(NavigationIds.StrengthAssessment) ?? 'Strengths Assessments',
          route: `${HOME_ROUTE_VALUE}${routes.strengthsAssessmentsList}`,
          icon: StrengthsAssessmentIcon,
          activeIcon: StrengthsAssessmentActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.StrengthAssessment),
          notificationBubble: alertIndicator.hasAllowedStrengthsAssessment,
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.EmotionalImpactScale) ?? 'Emotional Impact Scale',
          route: `${HOME_ROUTE_VALUE}${routes.emotionalImpactScaleList}`,
          icon: EmotionalImpactScaleIcon,
          activeIcon: EmotionalImpactScaleActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.EmotionalImpactScale),
          notificationBubble: alertIndicator.hasAllowedEmotionalImpactScale,
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.SelfEfficacyAssessment) ?? 'Self Efficacy Assessments',
          route: `${HOME_ROUTE_VALUE}${routes.selfEfficacyList}`,
          icon: DocumentIcon,
          activeIcon: DocumentActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.SelfEfficacyAssessment),
          notificationBubble: alertIndicator.hasAllowedSelfEfficacyAssessment,
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.DiseaseSeverityScreener) ?? 'Disease Severity Screener',
          route: `${HOME_ROUTE_VALUE}${routes.diseaseSeverityScreenerList}`,
          icon: DiseaseSeverityScreenerIcon,
          activeIcon: DiseaseSeverityScreenerActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.DiseaseSeverityScreener),
          notificationBubble: alertIndicator.hasAllowedDiseaseSeverityScreenerAssessment,
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.SymptomSeverityScale) ?? 'Symptom Severity Scale',
          route: `${HOME_ROUTE_VALUE}${routes.symptomSeverityScaleList}`,
          icon: DiseaseSeverityScreenerIcon,
          activeIcon: DiseaseSeverityScreenerActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.SymptomSeverityScale),
          notificationBubble: alertIndicator.hasAllowedDiseaseSeverityScreenerAssessment,
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.ChronicConditionChecklist) ?? 'Chronic Condition Checklist',
          route: `${HOME_ROUTE_VALUE}${routes.chronicConditionChecklists}`,
          icon: ChronicConditionChecklistIcon,
          activeIcon: ChronicConditionChecklistActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.ChronicConditionChecklist),
          notificationBubble: alertIndicator.hasAllowedChronicConditionChecklistAssessment,
        },
        {
          route: `${HOME_ROUTE_VALUE}${routes.strengthsAssessment}`,
          icon: StrengthsAssessmentIcon,
          activeIcon: StrengthsAssessmentActiveIcon,
          isVisible: false,
        },
        {
          route: `${HOME_ROUTE_VALUE}${routes.emotionalImpactScale}`,
          icon: EmotionalImpactScaleIcon,
          activeIcon: EmotionalImpactScaleActiveIcon,
          isVisible: false,
        },
        {
          route: `${HOME_ROUTE_VALUE}${routes.selfEfficacy}`,
          icon: DocumentIcon,
          activeIcon: DocumentActiveIcon,
          isVisible: false,
        },
        {
          route: `${HOME_ROUTE_VALUE}${routes.diseaseSeverityScreener}`,
          icon: DiseaseSeverityScreenerIcon,
          activeIcon: DiseaseSeverityScreenerActiveIcon,
          isVisible: false,
        },
        {
          route: `${HOME_ROUTE_VALUE}${routes.chronicConditionChecklist}`,
          icon: ChronicConditionChecklistIcon,
          activeIcon: ChronicConditionChecklistActiveIcon,
          isVisible: false,
        },
      ],
      isVisible: layouts.getNavigationVisibility(NavigationIds.Assessments),
    };

    const trellusTrackerLinks: INavItem = {
      label: layouts.getNavigationLabel(NavigationIds.TrellusTracker) ?? 'Trellus Tracker',
      subItems: [
        {
          label: layouts.getNavigationLabel(NavigationIds.LabResults) ?? 'Lab Results',
          route: `${HOME_ROUTE_VALUE}${routes.labResultsTracking}`,
          icon: LabResultIcon,
          activeIcon: LabResultActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.LabResults),
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.Medications) ?? 'Medications',
          route: `${HOME_ROUTE_VALUE}${routes.medications}`,
          icon: MedicationsIcon,
          activeIcon: MedicationsActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.Medications),
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.DailySymptomTracker) ?? 'Daily Symptoms',
          route: `${HOME_ROUTE_VALUE}${routes.dailySymptomTracker}`,
          icon: SymptomTrackingIcon,
          activeIcon: SymptomTrackingActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.DailySymptomTracker),
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.VaccinationRecord) ?? 'Vaccinations',
          route: `${HOME_ROUTE_VALUE}${routes.vaccination}`,
          icon: VaccinationsIcon,
          activeIcon: VaccinationsActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.VaccinationRecord),
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.WeeklyWellnessReport) ?? 'Weekly Wellness',
          route: `${HOME_ROUTE_VALUE}${routes.weeklyWellnessReport}`,
          icon: WeeklyWellnessReportIcon,
          activeIcon: WeeklyWellnessReportActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.WeeklyWellnessReport),
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.AddWeight) ?? 'Weight',
          route: `${HOME_ROUTE_VALUE}${routes.addWeight}`,
          icon: AddWeightIcon,
          activeIcon: AddWeightIconActive,
          isVisible: layouts.getNavigationVisibility(NavigationIds.AddWeight),
        },
      ],
      isVisible: layouts.getNavigationVisibility(NavigationIds.TrellusTracker),
    };

    const careVaultLinks: INavItem = {
      label: layouts.getNavigationLabel(NavigationIds.MyCareTools) ?? 'My Care Tools',
      subItems: [
        {
          label:
            layouts.getNavigationLabel(NavigationIds.GlossaryOfMedicationMonitoring) ??
            'Glossary of Medication Monitoring',
          route: glossaryOfMedicationMonitoringLink,
          icon: GlossaryOfMmMonitoring,
          activeIcon: GlossaryOfMmMonitoringActive,
          isVisible: layouts.getNavigationVisibility(NavigationIds.GlossaryOfMedicationMonitoring),
          type: NavItemType.ExternalLink,
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.VaccinationReferenceGuide) ?? 'Vaccination Reference Guide',
          route: vaccinationReferenceGuideLink,
          icon: VaccinationsReference,
          activeIcon: VaccinationsReferenceActive,
          isVisible: layouts.getNavigationVisibility(NavigationIds.VaccinationReferenceGuide),
          type: NavItemType.ExternalLink,
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.Reports) ?? 'Reports',
          route: `${HOME_ROUTE_VALUE}${routes.reports}`,
          icon: ReportsIcon,
          activeIcon: ReportsActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.Reports),
        },
        {
          label: layouts.getNavigationLabel(NavigationIds.MyTrellusConnectCard) ?? 'My TrellusConnect Card',
          icon: EmergencyCardIcon,
          activeIcon: EmergencyCardActiveIcon,
          isVisible: layouts.getNavigationVisibility(NavigationIds.MyTrellusConnectCard),
          type: NavItemType.Button,
          action: emergencyCardAction,
        },
      ],
      isVisible: layouts.getNavigationVisibility(NavigationIds.MyCareTools),
    };

    if (showAssessmentReportPageLink) {
      assessmentsLinks.subItems.push(...resilienceReportLinks);
    }

    return [
      {
        label: 'Home',
        route: `${HOME_ROUTE_VALUE}${routes.myDashboard}`,
        icon: HomeIcon,
        activeIcon: HomeActiveIcon,
        isVisible: true,
      },
      {
        label: layouts.getNavigationLabel(NavigationIds.Messages) ?? 'Messages',
        route: `${HOME_ROUTE_VALUE}${routes.messages}`,
        icon: MessagesIcon,
        activeIcon: MessagesActiveIcon,
        notificationBubble: alertIndicator.hasUnreadMessages,
        isVisible: layouts.getNavigationVisibility(NavigationIds.Messages),
      },
      {
        //label: layouts.getNavigationLabel(NavigationIds.MyCurriculum) ?? 'My Journey',
        label: 'My Journey',
        route: `${HOME_ROUTE_VALUE}${routes.myRoadmap}`,
        icon: EducationIcon,
        activeIcon: EducationActiveIcon,
        //isVisible: layouts.getNavigationVisibility(NavigationIds.MyCurriculum),
        isVisible: true,
      },
      {
        label: layouts.getNavigationLabel(NavigationIds.MyGoalJournal) ?? 'My Goal Journal',
        route: `${HOME_ROUTE_VALUE}${routes.goalJournal}`,
        icon: GoalMenu,
        activeIcon: GoalMenuActive,
        isVisible: layouts.getNavigationVisibility(NavigationIds.MyGoalJournal),
      },
      assessmentsLinks,
      trellusTrackerLinks,
      careVaultLinks,
      {
        label: layouts.getNavigationLabel(NavigationIds.Notifications) ?? 'Notifications',
        route: `${HOME_ROUTE_VALUE}${routes.notifications}`,
        icon: GreyBellVibrateIcon,
        activeIcon: YellowBellVibrateIcon,
        notificationBubble: alertIndicator.hasUnreadNotifications,
        isVisible: layouts.getNavigationVisibility(NavigationIds.Notifications),
      },
    ];
  };

  return (
    <aside className={classNames(className)}>
      <nav id="leftnav" className={styles.root}>
        {getNavItems()
          .filter((item) => item.isVisible)
          .map((nav) => (
            <NavItem forceShowLabel={open} {...nav} key={nav.label} />
          ))}
      </nav>
    </aside>
  );
};
const LeftNavPanelObserver = observer(LeftNavPanel);
const LeftNavPanelInjected: FC<ILeftNavPanelProps> = (props) => (
  <LeftNavPanelObserver {...props} layouts={useInjection(LAYOUTS_SERVICE)} />
);

export {LeftNavPanelInjected as LeftNavPanel};
